@import "../../../styles/variables";

.dialog-list {
	background-color: #ffffff;
	padding: 0 20px;
	display: flex;
	flex-direction: column;
	margin-right: 30px;
	width: 290px;
	&__header {
		& > * {
			font-weight: 500;
			color: $primary;
			font-size: 14px;
		}
		border-bottom: 1px solid #E0EDF5;
	}
	&__content {
		padding: 7% 0 0;
		margin-bottom: 10px;
		//max-height: 70vh;
		overflow: auto;
		flex: 1;
		& a {
			text-decoration: none;
			display: block;
			&.active {
				background-color: #DCEDFC;
			}
		}
	}
}

@media screen and (max-width: 1024px) {
	.dialog-list {
		width: 100%;
		margin-right: 0;
		min-height: 78vh;
		&__content {
			padding-top: 5px;
		}
		&__header {
			& > * {
				font-weight: 500;
				color: $primary;
				font-size: 18px;
			}
			margin-bottom: 5px;
		}
	}
}
