.reviews {
    margin-top: 50px;
    &__head {
        display: flex;
        align-items: center;
    }
    &__count {
        font-size: 22px;
        margin-right: 20px;
    }
    &__sum-rating {
        color: #009AF0;
        font-weight: bold;
        margin-left: 10px;
    }
    &__add-review {
        margin-left: auto;
    }
    &__list {
        list-style-type: none;
        padding: 0;
    }
    &__item {
        padding-bottom: 20px;
        width: 100%;
        border-bottom: 1px solid #EBEBEB;
        margin-bottom: 20px;
    }
}

.review {
    &__text {
        font-weight: 500;
        font-size: 14px;
        line-height: 26px;
        color: lighten(#000, 30);
    }
    &__meta {
        display: flex;
        align-items: center;
        &-right {
            margin-left: 10px;
            display: flex;
            flex-direction: column;
        }
    }
    &__user-name {
        font-size: 14px;
        color: #000000;
        font-weight: 600;
        margin-bottom: 5px;
    }
    &__date {
        color: lighten(#000, 30);
        font-weight: 500;
    }
}

@media screen and (max-width: 768px){
    .reviews {
        &__count {
            margin: 10px 0;
        }
        &__sum-rating {
            margin: 10px 0;
        }
        &__head {
            flex-direction: column;
            justify-content: center;
        }
        &__add-review {
            margin: 10px auto;
        }
        &__count {
            font-size: 16px;
        }
    }
}
