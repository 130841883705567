.text-field {
	cursor: text;
	display: inline-flex;
	font-size: 0.875rem;
	box-sizing: border-box;
	align-items: center;
	&__full-width {
		width: 100%
	}
	&__input {
		font: inherit;
		width: 100%;
		border: 0;
		margin: 0;
		display: block;
		min-width: 0;
		padding: 17px 19px;
		background: rgba(255, 255, 255, 0.5);
		border-radius: 4px;
		outline: 0;
		box-sizing: content-box;
		-webkit-tap-highlight-color: transparent;
		cursor: text;
		transition: all ease .15s;
		font-weight: bold;

		&::placeholder {
			color: rgba(255, 255, 255, .8);
			font-weight: bold;
		}
		&:hover, &:active, &:focus {
			background: rgba(255, 255, 255, 0.7);
		}
	}
}
