.dialog-item {
	display: flex;
	align-items: center;
	padding: 7.53px 16.39px 13.43px 11.44px;
	transition: background-color ease .15s;
	cursor: pointer;
	position: relative;
	border-bottom: 1px solid #E0EDF5;
	& * {
		text-decoration: none;
	}
	&:hover {
		background-color: #DCEDFC;
	}
	&__avatar {
		margin-right: 11px;
	}
	&__name {
		font-weight: 600;
		font-size: 16px;
		color: #202020;
	}
	&__last-message {
		&__text {
			font-size: 12px;
			color: #7793A4;
			margin-right: 16px;
		}
		&__time {
			margin-left: auto;
			font-size: 12px;
			white-space: nowrap;
			color: #9DB0BB;
		}
	}
	&__notifications {
		position: absolute;
		top: 0;
		left: 0;
		width: 20px;
		height: 20px;
		background: #009AF0;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #FFFFFF;
		font-weight: 500;
	}
}

@media screen and (max-width: 1024px) {
	.dialog-item {
		overflow: hidden;
	}
}
