.search-field {
	background-color: #fff;
	display: flex;
	//padding: 2px 2px;
	align-items: center;
    border-radius: 4px;
    &__btn-wrap {
      margin: 2px 2px;
    }
	&__places {
		width: 100%;
		position: relative;
		height: 56px;
		&-loading {
			height: inherit;
			display: flex;
			align-items: center;
		}
	}
	&__suggestions {
		position: absolute;
		top: 30px;
		left: 0;
		right: 0;
		background: #fff;
	}
	&__input {
		width: 100%;
		height: 100%;
		box-sizing: border-box;
		//margin: 0 0 0 8px;
		outline: none;
		border: none;
        &-wrap {
            width: 100%;
            position: relative;
            height: 56px;
            display: flex;
            align-items: center;
        }
		&__control {
			border: none !important;
			border-left: 1px solid rgba(0, 0, 0, 0.06) !important;
			box-shadow: none !important;
		}
		&__indicators {
			//display: none !important;
		}
		&__menu {
			margin-top: 8px !important;
		}
	}
}

.search-select {

  &__control {
    min-width: 120px;
    height: 54px;
    background-color: #EFF8FF !important;
    margin-right: 20px;
    border-width: 0 !important;
    border-radius: 4px 0px 0px 4px !important;
  }

  &__value-container {
    overflow: initial !important;
  }

  &__placeholder, &__single-value {
    font-family: Montserrat, sans-serif;
    text-transform: uppercase;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #59ACF0 !important;
    text-overflow: initial !important;
    white-space: initial !important;
  }

  &__indicator-separator {
    display: none;
  }

  &__dropdown-indicator {
    color: #59ACF0 !important;
  }
  &__menu {
    width: 120% !important;
    left: 50%;
    transform: translateX(-50%);
  }
  &__menu::before {
    content: "";
    position: absolute;
    top: -14px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-bottom: 7px solid #FFFFFF;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 7px solid transparent;
  }
  &__option {
    background-color: #FFFFFF !important;
    color: #222222;
    font-weight: 600;
  }

  &__option--is-selected {
    color: #59ACF0 !important;
  }

  &__option--is-focused {
    color: #FFFFFF !important;
    background-color: #59ACF0 !important;
  }
}

@media screen and (max-width: 768px) {
    .search-select {
        &__control {
            width: 100%;
        }
        &__menu {
            width: 100% !important;
            margin-top: 0 !important;
        }
        &__menu::before {
            display: none;
        }
    }

    .search-field {
        flex-direction: column;
        &__places {
            padding: 0 10px;
        }
        &__input {
            &-wrap {
                order: 1;
            }
        }
        &__btn {
            width: 100%;
            justify-content: center;
            &-wrap {
                order: 3;
                width: 100%;
                margin: 0;
            }
        }
        &__select-wrap {
            width: 100%;
            order: 2;
        }
    }
}
