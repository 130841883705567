@import "../../../styles/variables";
.home-layout {
	//background: $primary linear-gradient(180deg, rgba(10, 55, 91, 0.8) 0%, rgba(11, 21, 23, 0.8) 100%);
	position: relative;
	min-height: 100vh;
	background: linear-gradient(180deg, rgba(62, 169, 255, 0.8) 0%, rgba(0, 100, 218, 0.8) 99.99%, rgba(103, 200, 255, 0.8) 100%);
}

.home-layout::before {
	content: "";
	background-image: url(../../../images/main_bg_1.png);
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: -1;
	background-position-y: -80px;
}
.homepage-section{
	padding: 20px 10px;
}
.homepage-section.blue{
	background: #0295fb;
}
.homepage-container{
	max-width: 1000px;
	margin: 0 auto;
}
.homepage-section.no-padding-bottom{
	padding-bottom:0;
}

.homepage__row{
	display: flex;
	margin: 50px 0;
}
.homepage__column{
	width: 50%;
	display: flex;
    justify-content: center;
    justify-items: center;
    flex-direction: column;
}
.homepage__subheader{
	text-align: center;
	margin-top: 50px;
	font-size: 30px;
}
.homepage__image{
	max-width: 100%;
}
.homepage__subsubheader{
	font-weight: bold;
	font-size: 22px;
	margin-bottom: 10px;
}
.homepage__description{
	font-size: 500;
	font-size:18px;
	line-height: 30px;
}

.homepage-section.blue .homepage__subsubheader{
	color: #fff;
}
.homepage-section.blue .homepage__description{
	color: #fff;
}


@media screen and (max-width: 700px) {
	.homepage-section{
		padding: 10px;
	}
	.homepage__column{
		width: 100%;
	}
	.homepage__row{
		flex-direction:column;
		margin: 20px 0;
	}
	.homepage__subsubheader{
		margin-top: 20px;
	}
}