@import "../../styles/variables";

.org-event {
	background-color: #f8f7fa;
	padding-top: 79.5px;

	&__title {
		font-weight: 600;
		text-transform: uppercase;
		color: $primary;
		border-bottom: 1px solid #e1e1e1;
		padding-bottom: 18px;
		font-size: 20px;
	}

	& .form {
		&__submit {
			&-container {
				display: flex;
				justify-content: center;
				align-items: center;
				flex-direction: column;
				background-color: #fff;
				padding: 72px 10px 77px;
				border-bottom: 1px solid rgba(0, 0, 0, 0.06);
			}
			&-title {
				font-size: 32px;
				color: #112B39;
				font-weight: 600;
			}
			&-controls > *:not(:first-child) {
				margin-left: 10px;
			}
		}

		&__group {
			margin-bottom: 50px;
			position: relative;

			&__category {
				margin-top: 16px;
			}
			&__label {
				color: #535657;
				font-size: 0.875rem;
				font-weight: 500;
				margin: 0;
				& > .required {
					color: red;
				}
			}
		}
	}

	& .form__grid {
		display: grid;

		& .form-dates {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-gap: 15px;

			input {
				box-sizing: border-box;
			}
		}

		&.col-3 {
			grid-template-columns: 1fr 1fr 1fr;
		}

		&.gap-5 {
			grid-gap: 50px;
		}
	}
}

.autocomplete-dropdown-container {
	background-color: #fff;
	width: 100%;
	//margin-top: 3px;
	position: absolute;
	z-index: 1000;
	right: 0;
	left: 0;
	padding: 10px 10px 0;
	box-sizing: border-box;

	& > div {
		border-bottom: 1px solid #e0e0e0;
		padding-bottom: 6px;
		margin-bottom: 6px;
		cursor: pointer;

		&:last-child {
			border-bottom: none;
		}
	}
}

@media screen and (max-width: 768px){
	.org-event {
		& .form__grid.col-3 {
			grid-template-columns: 1fr;
		}
		& .form__grid {

		}
	}
}
