.paged-places {
    flex: 1;
    height: 100%;
    &__list {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 2vw;
        //border-top: 1px solid #E1E1E1;
        padding-top: 45px;
    }
    &__title {
        color: #59ACF0;
        font-size: 20px;
        text-transform: uppercase;
        padding-bottom: 20px;
        font-weight: 600;
        border-bottom: 1px solid #E1E1E1;
    }
    padding: 30px;
    width: 1366px;
    margin: 0 auto;

    &__item {
        box-shadow: 2px 3px 8px rgba(159, 159, 159, 0.11);
        width: 100%;
    }
}

@media (max-width: 1366px)  {
    .paged-places {
        width: 100%;
        &__list {
            grid-template-columns: 1fr 1fr 1fr;
        }
    }
}

@media (max-width: 1024px)  {
    .paged-places {
        &__list {
            grid-template-columns: 1fr 1fr;
        }
    }
}

@media (max-width: 524px)  {
    .paged-places {
        &__list {
            grid-template-columns: 1fr;
        }
    }
}


