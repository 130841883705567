.page-placeholder {
    padding-top: 45px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #59ACF0;
    font-size: 16px;
    text-transform: uppercase;
    margin-bottom: 17px;
    & > img {
        max-width: 100%;
    }
}
