.places {
    &-subtypes {
        &__control {
            width: 320px !important;
            background-color: #EFF8FF !important;
            margin-right: 20px;
            border-color: #59ACF0 !important;
            border-style: solid !important;
            border-width: 1px !important;
        }

        &__value-container {
            overflow: initial !important;
        }

        &__placeholder, &__single-value {
            font-family: Montserrat, sans-serif;
            text-transform: uppercase;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            color: #59ACF0 !important;
            text-overflow: initial !important;
            white-space: initial !important;
        }

        &__indicator-separator {
            display: none;
        }

        &__dropdown-indicator {
            color: #59ACF0 !important;
        }
        &__menu::before {
            content: "";
            position: absolute;
            top: -14px;
            left: 50%;
            transform: translateX(-50%);
            width: 0;
            height: 0;
            border-bottom: 7px solid #FFFFFF;
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
            border-top: 7px solid transparent;
        }
        &__option {
            background-color: #FFFFFF !important;
            color: #222222;
            font-weight: 600;
        }
        &__option--is-selected {
            color: #59ACF0 !important;
        }

        &__option--is-focused {
            color: #FFFFFF !important;
            background-color: #59ACF0 !important;
        }
        &__multi-value {
            background-color: #60B6FB !important;
            color: #FFFFFF;
            &__label {
                color: #FFFFFF !important;
            }
        }
        &__clear-indicator {
            color: #DB0202 !important;
        }
        &__clear-indicator:hover {
            opacity: 0.7;
        }
    }
}

@media screen and (max-width: 1440px) {
    .places {
        &-subtypes {
            &__control {
                width: 100% !important;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    //.places {
    //   flex-direction: column;
    //}
}

//position: absolute;
//top: 0px;
//left: 0px;
//right: 0px;
//bottom: 0px;
//overflow: scroll;
//margin-right: -17px;
//margin-bottom: -17px;
