.slide-btn {
    position: relative;
    overflow: hidden;
    //transition: transalate .8s;
    & > button {
        width: 100%;
    }
    & > button:nth-child(2){
        position: absolute;
        right: 100%;
        transition: right .5s;
    }
    &:hover > button:nth-child(2) {
        right: 0;
    }
}
