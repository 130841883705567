@import "../../styles/variables";

.header {
	padding: 15px 1.5% 17px;
	display: flex;
	align-items: center;

	&__search {
		margin-left: 35px;
		width: 420px;
	}

	&.variant {
		&-transparent {
			background-color: transparent;
		}

		&-primary {
			background-color: $blue;
		}
	}

	&__logo {
		max-width: 120px;
		width: 100%;
	}

	&__nav {
		margin-left: auto;
		&-item {
			position: relative;
		}
		&-notifications {
			position: absolute;
			top: -20px;
			right: -6px;
			width: 20px;
			height: 20px;
			background: #DB0202;
			display: flex;
			justify-content: center;
			align-items: center;
			color: #FFFFFF;
			font-weight: 500;
		}

		& ul {
			list-style-type: none;
			display: flex;
			align-items: center;
			margin: 0;

			& li {
				margin: 0 29px;

				& a {
					text-decoration: none;
					color: #ffffff;
					padding: 16px 16px;
					transition: all ease .15s;
					font-size: 16px;
					font-weight: 600;

					&:hover {
						background-color: rgba(255, 255, 255, .1);
					}
				}
			}
		}
	}

	&__dropdown {
		&-button {
			display: flex;
			flex-direction: row;
			align-items: center;
			cursor: pointer;

			& > i {
				margin-left: 8px;
				color: #ffffff;
			}
		}

		&-content {
			&-group {
				border-bottom: 1px solid #f2f1f1;
				padding-bottom: 17px;
				margin-bottom: 17px;
			}
			& ul {
				display: block;
				padding: 0;
				& > li {
					display: flex;
					align-items: center;
					justify-content: space-between;
					margin: 0 0 17px;
					&:last-child {
						margin: 0;
					}
					&:hover {
						color: $primary;
						& > a {
							color: $primary;
						}
					}
					& > a {
						color: #000000;
						font-weight: 500;
						padding: 0;
						width: 100%;
					}
					& > span {
						white-space: nowrap;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 1440px){
	.header {
		&__nav {
			&-list {
				padding-left: 15px;
			}
			&-list > &-item {
				margin: 0;
			}
			&-list > &-item:last-of-type {
				margin-right: 30px;
			}
		}
	}
}


@media screen and (max-width: 768px){
	.header {
		justify-content: center;
		position: relative;
		&__nav {
			margin-left: initial;
			&-burger {
				position: absolute;
				right: 10px;
				top: 50%;
				transform: translateY(-50%);
			}
			&-list.desktop {
				display: none;
			}
		}
		&__search {
			display: none;
		}
	}
}
