@import "../../styles/variables";

.icon-button {
	flex: 0 0 auto;
	padding: 12px;
	overflow: visible;
	font-size: 1.5rem;
	text-align: center;
	transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	border: 0;
	cursor: pointer;
	display: inline-flex;
	outline: 0;
	position: relative;
	align-items: center;
	user-select: none;
	vertical-align: middle;
	justify-content: center;
	text-decoration: none;
	background-color: transparent;
	border-radius: 50%;

	&:hover {
		&:not(.without-feedback) {
			background-color: rgba(0, 0, 0, 0.08);
		}
	}

	&:active {
		&:not(.without-feedback) {
			background-color: rgba(0, 0, 0, 0.2);
		}
	}

	&.variant {
		&-contained {
			&.color {
				&-primary {
					background-color: $primary;

					& > * {
						color: #fff;
						font-size: 22px;
					}
					&:hover {
						background-color: lighten($primary, 10);
					}
					&:active {
						background-color: darken($primary, 10);
					}
				}
			}
		}
		&-text {
			&.color {
				&-grey {
					& > * {
						color: #9DB0BB;
					}
				}
			}
		}
	}
}
