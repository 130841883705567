.trimate-modal {
    position: absolute;
    //top: 40px;
    //left: 40px;
    //right: 40px;
    //bottom: 40px;
    min-width: 400px;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 20px;
    &__close {
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;
    }
}


@media screen and (max-width: 768px){
    .trimate-modal {
        min-width: initial;
        max-width: 500px;
        width: 100%;
    }
}
