@import "../../styles/variables";

.forgot-page {
    &__form {
        &__text-field {
            margin-bottom: 15px;
        }
    }
    &__footer {
        margin-top: 38px;
        border-top: 1px solid rgba(255, 255, 255, 0.22);
        padding: 33px 0;
        display: flex;
        justify-content: space-between;
    }
}

