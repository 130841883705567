@import '../../styles/variables.scss';

.place{
    border-radius: 3px;
    background: $blue;
    overflow: hidden;
    text-decoration: none;
    color: #000000;
    display: flex;
    flex-direction: column;
    &-photo{
        position: relative;
        width: 100%;
        padding-top: 44.6%;
        font-family: $heart;
        &__text{
            color: #fff;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            font-size: 45px;
        }
        &__inner{
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-position: center;
            background-size: cover;
        }
        &__like{
            display: inline-block;
            position: absolute;
            right: 11px;
            top: 12px;
            cursor: pointer;
            img{
                width: 24px;
                &.place-photo__like_hover{
                    opacity: 0;
                    transition: opacity .15s ease;
                    position: absolute;
                    left: 0;
                }
            }
            &:hover{
                .place-photo__like_hover{
                    opacity: 1;
                }
            }
        }
    }
    &-info{
        background: #fff;
        padding: 14px 15px 9px;
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        min-height: 130px ;
        &__left{
            flex-grow: 1;
        }
        &__right{
            text-align: right;
            font-size: 12px;
            font-weight: bold;
            margin-left: 30px;
        }
        &__meta{
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            text-transform: uppercase;
            font-size: 10px;
            white-space: nowrap;
            flex-wrap: wrap;
            margin-top: 7px;
        }
    }
    &__name{
        font-size: 18px;
        line-height: 22px;
    }
    &__activities {
        flex-direction: row;
        display: inline-flex;
    }
    &__activity{
        height: 23px;
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 8px;
        border-radius: 2px;
        color: #fff;
        margin-right: 7px;
        background: #ccc;
        &.blue{
            background: $blue;
        }
        &.green{
            background: $green;
        }
        &.pink{
            background: $pink;
        }
    }
    &-params{
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        color: rgba(0, 0, 0, 0.77);
        line-height: 11px;
        font-weight: bold;
        margin: 10px 0;
        &__item{
            padding-right: 5px;
            margin-right: 5px;
            border-right: 1.5px solid;
            &_rate{
                padding-right: 0;
                margin-left: 0;
                border: none;
                margin-top: -1px;
            }
        }
        &__star{
            color: $blue;
            margin-right: 3px;
            height: 11px;
        }
    }
    &__price{
        font-size: 22px;
        margin-top: -3px;
        font-weight: 500;
    }
}
