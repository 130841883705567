@import "../../styles/variables";

.review-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 600px;
    padding: 0 40px;
    flex: 1;
    &__title {
        margin: 10px 0;
        text-align: center;
        white-space: pre-wrap;
    }
    &__desc {
        margin: 10px 0;
        text-align: center;
        white-space: pre-wrap;
    }
    &__row {
        text-align: center;
        width: 100%;
        margin-top: 20px;
    }
    &__text {
        border: 0 !important;
        font-weight: 600 !important;
        font-size: 18px !important;
        background-color: lighten(#EEEEEE, 4) !important;
        &:hover, &:focus, &:active {
            border: 0 !important;
        }
    }
}

@media screen and (max-width: 768px){
    .review-modal {
        min-width: initial;
        padding: 0 15px;
    }
}
