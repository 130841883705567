@import "../../styles/variables";
.form-upload {
	$main: &;
	background-color: #fff;
	padding: 16px;
	&__header {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		& p {
			color: #535657;
			font-weight: 600;
			& > span {
				color: red;
			}
		}
		&_position {
			&_center {
				justify-content: center;
			}
		}
	}
	&__container {
		border: 2px dotted #E5E2E2;
		padding: 10.7%;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		position: relative;
		cursor: pointer;
		& > * {
			margin: 0;
		}
	}
	&__title {
		margin: 13px 0;
		text-transform: uppercase;
		color: $primary;
		font-size: 30px;
	}
	&__text {
		font-size: 16px;
		color: #112B39;
		font-weight: 600;
	}
	&__info {
		font-size: 12px;
		color: #768791;
		font-weight: 600;
	}
	&__thumb {
		width: 100%;
		height: 100%;
		position: absolute;
		object-fit: cover;
	}
	&_shape {
		&_round {
			border-radius: 50%;
			display: inline-flex;
			padding: 2%;

			& > #{$main}__container {
				border-radius: 50%;
				display: inline-flex;
				padding: 0;
				flex: 1;
				width: 150px;
				height: 150px;
				& > #{$main}__thumb {
					width: 105%;
					height: 105%;
					border-radius: 50%;
				}
			}
		}
	}
}
