@import "../../styles/variables";

.signup-page {
    &__form {
        &__text-field {
            margin-bottom: 15px;
        }
        &__upload-field {
            margin-bottom: 40px;
        }
        &__field-link {
            display: block;
            color: #FFFFFF;
            font-weight: 600;
            margin-top: 8px;
            font-size: 14px;
        }
    }
    &__privacy {
        color: rgba(#FFFFFF, 0.7);
        & a {
            color: #FFFFFF;
            text-decoration: none;
        }
    }
    &__footer {
        margin-top: 38px;
        border-top: 1px solid rgba(255, 255, 255, 0.22);
        padding: 33px 0;
    }
}

