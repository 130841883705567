@import "../../../styles/variables";

.signpage {
	min-height: 100vh;
	//background: $primary linear-gradient(180deg, rgba(10, 55, 91, 0.8) 0%, rgba(11, 21, 23, 0.8) 100%);
	background: linear-gradient(180deg, rgba(62, 169, 255, 0.8) 0%, rgba(0, 100, 218, 0.8) 99.99%, rgba(103, 200, 255, 0.8) 100%);
	padding: 20px 1.6%;
	position: relative;
	&::before {
		content: "";
		background-image: url(../../../images/main_bg_1.png);
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: -1;
	}

	&__header {
		margin-bottom: 10px;
		display: flex;
		&__logo {
			width: 100%;
			max-width: 207px;
			height: auto;
		}
	}
	&__content {
		&-close {
			position: absolute;
			top: 5px;
			right: 5px;
		}
		width: 32%;
		//margin: 8% auto 0;
		margin: 0 auto;
		padding-left: 2%;
		padding-right: 2%;
		padding-top: 50px;
		text-align: center;
		background: rgba(255, 255, 255, 0.24);
		position: relative;
	}
}

.close-button {
	position: absolute;
	right: 1.7%;
}

@media only screen and (max-width : 992px) {
	.signpage {
		&__content {
			width: 50%;
		}
	}
}

@media only screen and (max-width : 768px) {
	.signpage {
		&__content {
			width: 70%;
		}
	}
}

@media only screen and (max-width : 480px) {
	.signpage {
		&__content {
			width: 96%;
		}
	}
}


