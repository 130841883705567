.message {
	$main: &;
	display: flex;
	width: 60%;
	margin-bottom: 26px;
	display: -webkit-box;
	align-items: stretch;

	&__text {
		margin: 0 19px;
		padding: 15px 24px;
		border-radius: 33px;
		background-color: #fff;
		box-shadow: 0px 4px 4px rgba(159, 159, 159, 0.11);
		position: relative;
		max-width: 370px;
		word-break: break-word;
		&::before {
			content: "";
			background-color: #ffffff;
			box-shadow: 0px 4px 4px rgba(159, 159, 159, 0.11);
			position: absolute;
			width: 25px;
			height: 25px;
			left: -6px;
			top: 50%;
			transform: translateY(-50%) rotate(45deg);
		}
	}
	&__attachments {
		list-style-type: none;
		padding: 0;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}

	&__attachment {
		width: 150px;
		position: relative;
		margin: 5px;
		cursor: pointer;
		transition: transform 0.3s ease-out;
		&:hover{
			transform: scale(1.05);
		}
		&-img {
			width: 100%;
			padding-top: 56.25%;
			background-size: cover;
			background-position: center;
		}
	}

	&__my {
		flex-direction: row-reverse;
		align-self: flex-end;
		-webkit-box-direction: reverse;
		
		& > .message__text {
			background-color: #43A9EE;
			color: #ffffff;
			box-shadow: 1px 1px 4px rgba(67, 169, 238, 0.25);
			position: relative;
			&::before {
				background: none;
				box-shadow: none;
			}
			&::after {
				content: "";
				background-color: #43A9EE;
				position: absolute;
				width: 25px;
				height: 25px;
				right: -6px;
				top: 50%;
				transform: translateY(-50%) rotate(45deg);
			}
		}
	}
}

@media screen and (max-width: 1024px){
	.message {
		width: auto;
		flex: 1;

		//height: 200px;
		&__text {
			//height: 60px;
			max-width: 100%;
		}
	}
}
