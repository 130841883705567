@import '../../styles/variables.scss';

.event{
    min-height: 100vh;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-direction: column;
    padding-bottom: 50px;
    &__preloader{
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        flex-grow: 1;
        flex-direction: column;
    }
    &__photo{
        &::before {
            content: "";
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.43), rgba(0, 0, 0, 0.43));
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }
        position: relative;
        background-color: $blue;
        background-position: center;
        background-size: cover;
        height: 422px;
        flex-shrink: 0;
    }
    &-info{
        height: 100%;
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        padding-bottom: 50px;
        position: relative;
        &__control {
            position: absolute;
            top: 25px;
            right: 25px;
            &-btn {
                &:first-child {
                    margin-right: 10px;
                }
                & > .btn__icon {
                    margin-right: 10px;
                }
            }
        }
        &__meta{
            display: -webkit-flex;
            display: -ms-flex;
            display: flex;
            font-size: 14px;
            color: #fff;
            align-items: baseline;
            text-transform: uppercase;
            margin-top: -10px;
        }
    }
    &__name{
        font-size: 32px;
        color: #fff;
        font-weight: bold;
    }
    &__activities{
        margin-right: 30px;
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        align-items: center;
        margin-top: 25px;
        flex-wrap: wrap;
        &-item{
            display: -webkit-flex;
            display: -ms-flex;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 40px;
            padding: 20px;
            margin-bottom: 15px;
            &:not(:last-child){
                margin-right: 15px;
            }
        }
    }
    &-params{
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        font-weight: bold;
        margin-top: 25px;
        &__star{
            color: $blue;
            margin-right: 3px;
        }
        &__item{
            height: 19px;
            &:not(:last-child){
                padding-right: 7px;
                margin-right: 7px;
                border-right: 2px solid #fff;
            }
        }
    }
    &-main {
        padding-top: 30px;
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        flex: 1;
        &__worktime {
		    margin: 5px 0;
	    }
        &__going {
            justify-content: center;
            &-icon {
                margin-right: 5px;
            }
            &-btn {
                width: 100%;
                justify-content: center;
            }
        }
	    &__left {
            flex: 1;
            margin-right: 50px;
        }
        &__right{
            max-width: 357px;
            flex-grow: 1;
            width: 100%;
            &-info{
                border: 1px solid #e8e2e2;
                border-top: 0;
                padding-top: 5px;
            }
            &-item{
                font-weight: bold;
                padding: 15px 0;
                margin: 0 15px;
                font-size: 12px;
                display: -webkit-flex;
                display: -ms-flex;
                display: flex;
                align-items: flex-start;
                &-text{
                    margin-top: 4px;
                }
                img{
                    margin-right: 15px;
                    flex: none;
                }
                &:not(:last-child){
                    border-bottom: 1px solid #e8e2e2;
                }
            }
        }
        &__name{
            font-size: 32px;
            font-weight: bold;
            color: #000;
            margin-bottom: 25px;
        }
        &__description, &__details{
            color: #000;
            font-size: 14px;
            line-height: 1.55;
            margin-bottom: 60px;
        }
        &__details-title{
            margin-bottom: 25px;
            color: #000;
            font-size: 18px;
            font-weight: bold;
        }
        &__map{
            height: 190px;
            background-image: url('https://static.tildacdn.com/tild6337-3361-4631-b365-303937616166/photo.png');
        }
    }
}

@media screen and (max-width: 950px) {
    .event-info__meta{
        flex-direction: column;
    }
}


@media screen and (max-width: 768px){
    .event {
        &-main {
            justify-content: flex-end;
            flex-direction: column-reverse;
            &__right {
                max-width: initial;
                flex-grow: initial;
            }
            &__left {
                max-width: initial;
                margin-right: 0;
            }
        }
    }
}
