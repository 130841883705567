.event-avatar {
	border-radius: 5%;
	overflow: hidden;
	background-position: center;
	background-size: cover;
	&.size {
		&-small {
			width: 47px;
			height: 47px;
		}
		&-normal {
			width: 47px;
			height: 47px;
		}
		&-big {
			width: 47px;
			height: 47px;
		}
	}
}
