.dropdown {
	position: relative;
	display: inline-block;
	&__content {
		position: absolute;
		min-width: 160px;
		z-index: 50;
		background-color: #fff;
		padding: 12px;
		transform: translate(-70%, 18px);
		transition: all cubic-bezier(0.55, 0.09, 0.68, 0.53) .05s;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.11);

		&:before {
			content: "";
			position: absolute;
			width: 23px;
			height: 23px;
			background-color: #fff;
			transform: rotate(45deg);
			right: 10%;
			top: -5px;
			z-index: -10;
		}
		&.hide {
			display: none;
		}
	}
}
