@import "../../styles/variables";
.app-footer {
	padding-bottom: 32px;
	padding-top: 49px;
	&__inner {
		display: grid;
		grid-template-columns: 1fr 2fr 1fr;
	}
	&__logo {
		&-img {
			max-width: 207px;
			width: 100%;
		}

		&-copyright {
			font-size: 14px;
		}
	}

	&__menu {
		& > ul {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			grid-gap: 8px;
			list-style-type: none;
		}
		&-link {
			& > a {
				text-decoration: none;
				color: #000;
				font-size: 14px;
				font-weight: 500;
				&:hover {
					color: $primary
				}
			}
		}
	}
}

@media screen and (max-width: 768px) {
	.app-footer {
		&__inner {
			flex-direction: column;
			grid-template-columns: 1fr;
		}
		&__logo {
			text-align: center;
		}
	}
}
