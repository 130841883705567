@import '../../styles/variables.scss';

.filters {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    align-items: center;
    padding-right: 30px;
    margin-top: -12px;
    width: 100%;
}

.sort{
    width: 143px;
    font-size: 14px;
    color: #052935;
    margin-top: 12px;
    flex: none;
    cursor: pointer;
    .css-1okebmr-indicatorSeparator{
        display: none;
    }
    .css-yk16xz-control, .css-1pahdxg-control{
        height: 100%;
        border: none;
        background: none;
        box-shadow: none;
        cursor: pointer;
    }
    .css-1uccc91-singleValue{
        right: 0;
        margin-right: 4px;
    }
    .css-tlfecz-indicatorContainer, .css-1gtu0rj-indicatorContainer{
        padding: 0;
        color: #052935;
    }
    .css-9gakcf-option{
        background-color: $blue;
    }
    .css-1ml51p6-MenuList{
        padding: 0;
        div{
            height: 40px;
            line-height: 40px;
            cursor: pointer;
            padding: 0;
            padding-left: 13px;
        }
    }
    .css-26l3qy-menu{
        border-radius: 0;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.11);
        right: -9px;
        margin-top: 0;
        &:before{
            content: '';
            background: #ffffff;
            width: 13px;
            height: 13px;
            transform: rotate(45deg);
            position: absolute;
            top: -5px;
            right: 20px;
        }
    }
}

.activities{
    height: 100%;
    flex-grow: 1;
    &__item{
        height: 44px;
        border-radius: 3px;
        border: 2px solid;
        display: -webkit-inline-flex;
        display: -ms-inline-flex;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        transition: all .1s ease-in-out;
        margin-right: 12px;
        margin-top: 12px;
        padding: 0 15px;
        cursor: pointer;
        text-transform: uppercase;
        &:not(.active):hover{
            opacity: .8;
        }
        &.green{
            color: $green;
            &.active, &:hover{
                background: $green;
                border-color: $green;
                color: #fff;
            }
        }
        &.blue{
            color: $blue;
            &.active, &:hover{
                background: $blue;
                border-color: $blue;
                color: #fff;
            }
        }
        &.pink{
            color: $pink;
            &.active, &:hover{
                background: $pink;
                border-color: $pink;
                color: #fff;
            }
        }
    }
}


@media screen and (max-width: 1440px) {
    .filters {
        flex-direction: column;
        padding-right: 0;
        margin-top: 0;
        & > .sort {
            margin: 20px 0 30px;
        }
    }
    .activities {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 2vw;
        &__item {
            margin: 0;
        }
    }
}
