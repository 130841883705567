.share-option {
    display: flex;
    align-items: center;
    cursor: pointer;
    &__name {
        font-weight: 500;
        font-size: 16px;
    }
    &__icon {
        margin-right: 10px;
    }
}
