@import "./variables.scss";
@import "./normalize";
@import "./helepers";
@import "../fonts/font-awesome/css/font-awesome.min.css";
@import "../../node_modules/hamburgers/_sass/hamburgers/hamburgers";

* {
	box-sizing: border-box;
}
a {
	cursor: pointer;
}
@font-face {
	font-family: MonsterRat;
	src: url("../fonts/Montserrat-Regular.ttf");
	font-weight: 400;
}

@font-face {
	font-family: MonsterRat;
	src: url("../fonts/Montserrat-Medium.ttf");
	font-weight: 500;
}

@font-face {
	font-family: MonsterRat;
	src: url("../fonts/Montserrat-SemiBold.ttf");
	font-weight: 600;
}

@font-face {
	font-family: MonsterRat;
	src: url("../fonts/Montserrat-Bold.ttf");
	font-weight: 700;
}

@font-face {
	font-family: Bring Heart;
	src: url("../fonts/Bring Heart.ttf");
	font-weight: bold;
}

html,
body {
	font-family: $rat, sans-serif;
	font-size: 16px;
	min-width: 320px;
	position: relative;
	min-height: 100%;
	height: 100%;
}

#root, .app-body {
	height:100%;
}
.app {
	background: #f7f7f7;
	color: #000;
	height: 100%;
	width: 100%;
}

.container {
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto;
}

.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
	padding-right: 2%;
	padding-left: 2%;
	margin-right: auto;
	margin-left: auto;
}

.places {
	min-width: 768px;
	//max-width: 810px;
	//padding-left: 21px;
	//padding-top: 12px;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding-left: 21px;
	padding-top: 12px;
	&__list {
		display: -webkit-flex;
		display: -ms-flex;
		display: flex;
		flex-wrap: wrap;
		max-height: 100%;
		width: 100%;
		padding-right: 20px;
		&.full {
			height: 100%;
		}

		&-wrapper {
			position: relative;
			width: 100%;
			padding-top: 8px;
			margin-top: 8px;
			flex-grow: 1;
		}

		.place {
			margin-bottom: 20px;
			width: calc(50% - 10px);

			&:not(:nth-child(2n)) {
				margin-right: 20px;
			}
		}
	}

	&__preloader {
		background: rgba(255, 255, 255, 0.9);
		padding-bottom: 90px;

		&-wrapper {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			padding-right: 19px;
			z-index: 1;
		}
	}

	&__no-match {
		display: -webkit-flex;
		display: -ms-flex;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
		padding-bottom: 90px;
		color: #353535;
		font-size: 20px;
	}
}

#filters-btn {
	display: none;
	height: 44px;
	border-radius: 3px;
	border: 2px solid;
	display: -ms-inline-flex;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	font-size: 12px;
	transition: all 0.1s ease-in-out;
	margin-right: 12px;
	padding: 0 15px;
	cursor: pointer;
	text-transform: uppercase;
	color: #fff;
	border-color: $blue;
	background: $blue;
	transition: all 0.1s ease;
	cursor: pointer;

	&:hover {
		opacity: 0.8;
	}
}

.map-section {
	width: 100%;
	position: relative;
}

.filters__modal {
	font-family: $rat;
	padding: 20px 50px 60px;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	position: absolute;
	background: #ffffff;
	box-shadow: 0 0 12px -6px #000;
	border-radius: 8px;
	outline: none;
	z-index: 6;

	&-title {
		margin: 10px 0 30px;
		text-align: center;
	}
	&-places {
		margin: 10px 0;
	}
	&-close {
		position: absolute;
		right: 10px;
		top: 10px;
		padding: 5px;
		cursor: pointer;
		transition: all 0.2s ease;
		font-size: 20px;

		&:hover {
			opacity: 0.8;
		}
	}
}

.ReactModal__Overlay {
	z-index: 5;
}

.separator {
	height: 0;
	width: 100%;
	border: 1px solid rgba(255, 255, 255, 0.22);
}

@media screen and (max-width: 1440px) {
	.places {
		min-width: 400px;

		.place {
			width: 100%;

			&:not(:nth-child(2n)) {
				margin-right: 0;
			}
		}

		#filters-btn {
			display: -webkit-inline-flex;
			display: -ms-inline-flex;
			display: inline-flex;
		}
	}
}

@media (min-width: 576px) {
	.container {
		max-width: 540px;
	}
	.container-sm {
		max-width: 540px;
	}
}

@media (min-width: 768px) {
	.container {
		max-width: 720px;
	}
	.container-md {
		max-width: 720px;
	}
}

@media (min-width: 992px) {
	.container {
		max-width: 960px;
	}
	.container-lg {
		max-width: 960px;
	}
}

@media (min-width: 1200px) {
	.container {
		max-width: 1440px;
	}
	.container-xl {
		max-width: 1140px;
	}
}

@media screen and (max-width: 768px){
	.filters__modal {
		padding: 5px 10px 20px;
	}

	.map-section {
		width: 100%;
		height: 300px;
	}
	.places {
		min-width: initial;
		padding: 12px 12px !important;
		&__preloader {

			&-wrapper {
				padding-right: 0;
			}
		}
		&__list {
			padding-right: 0;
			&-wrapper{
				height: 500px;
			}
		}
	}
}
