.chat-page {
	background-color: #F8F7FA;
	//height: 88vh;
	padding-top: 34px;
	padding-bottom: 40px;
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: center;
	height: 100%;
}

@media screen and (max-width: 768px) {
	.chat-page {
		width: 100%;
		padding: 10px 5px;
	}
}
