.user-avatar {
	border-radius: 50%;
	object-fit: cover;
	max-width: 100%;
	background-color: #FFFFFF;
	overflow: hidden;
	flex-shrink: 0;
	&__icon {

	}

	&.size {
		&-small {
			width: 47px;
			height: 47px;
		}
		&-normal {
			width: 47px;
			height: 47px;
		}
		&-big {
			width: 47px;
			height: 47px;
		}
	}
}
