.homepage {
	max-width: 740px;
	display: flex;
	flex-direction: column;
	margin: 10% auto 0;
	& > h1 {
		text-align: center;
		color: #FFF;
	}
}

@media only screen and (max-width : 992px) {
	.homepage {
		width: 70%;
	}
}

@media only screen and (max-width : 768px) {
	.homepage {
		width: 90%;
	}
}

