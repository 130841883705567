@import "../../styles/variables";

@mixin default-button($base-color, $font-color) {
	background-color: $base-color;
	color: $font-color;

	&:hover {
		background-color: lighten($base-color, 5);
	}

	&:active, &:target {
		background-color: darken($base-color, 10);
	}
}

.btn {
	display: inline-flex;
	font-size: 16px;
	text-align: center;
	align-items: center;
	background: none;
	border: none;
	outline: none;
	cursor: pointer;
	border-radius: 4px;
	font-weight: 600;
	transition: all ease .15s;
	text-decoration: none;
	$main: &;

	&-primary {
		&__variant {
			&-outlined {
				border: 1px solid $primary;
				color: $primary;

				&:hover {
					background-color: lighten($primary, 5);
					color: #FFFFFF;
				}

				&:active, &:target {
					background-color: darken($primary, 10);
				}
			}

			&-contained {
				background-color: $primary;
				color: #FFFFFF;

				&:hover {
					background-color: lighten($primary, 5);
				}

				&:active, &:target {
					background-color: darken($primary, 10);
				}
			}
			&-onlytext{
				color: $primary;
				&:hover {
					color: lighten($primary, 20);
				}
				&:active, &:target {
					color: darken($primary, 20);
				}
			}
		}
	}

	&-white {
		&__variant {
			&-outlined {
				border: 2px solid #FFFFFF;
				color: #FFFFFF;

				&:hover {
					background-color: rgba(255, 255, 255, .1);
				}

				&:active, &:target {
					background-color: darken(#FFFFFF, 10);
				}
			}

			&-contained {
				background-color: #FFFFFF;
				color: $primary;

				&:hover {
					background-color: lighten(#FFFFFF, 5);
				}

				&:active, &:target {
					background-color: darken(#FFFFFF, 10);
				}
			}
			&-onlytext{
				&:hover {
					background-color: rgba(255, 255, 255, .1);
				}
				&:active, &:target {
					background-color: rgba(255, 255, 255, .2);
				}
			}
		}
	}

	&-facebook {
		@include default-button(#3B5998,#fff);
	}

	&-green {
		@include default-button(#1ED760,#fff)
	}

	&-red {
		@include default-button(#DB0202,#fff);
	}

	&-grey {
		@include default-button(#7C7C7C,#fff);
	}

	&__icon {
		margin-right: 16px;
	}

	&-with-icon {
		padding-left: 13px;
	}

	&__size {
		&-normal {
			padding: 16px 29px;
			height: 52px;
			&#{$main}_shape {
				&_round {
					border-radius: 40px;
				}
			}
		}

		&-small {
			padding: 9px 16px;
			font-size: 14px;
			&#{$main}_shape {
				&_round {
					border-radius: 30px;
				}
			}
		}

		&-block {
			display: flex;
			justify-content: center;
			padding: 16px 29px;
			width: 100%;
		}
	}
}
