@import "../../styles/variables";

.form-field {
	cursor: text;
	display: inline-flex;
	align-items: flex-start;
	justify-content: flex-end;
	flex-direction: column;
	font-size: 0.875rem;
	box-sizing: border-box;
	font-weight: 500;
	& label {
		color: #535657;
	}
	&__full-width {
		width: 100%
	}
	&__input {
		font: inherit;
		resize: none;
		width: 100%;
		border: 1px solid transparent;
		margin: 0;
		display: block;
		min-width: 0;
		padding: 17px 19px;
		background: rgb(255, 255, 255);
		border-radius: 4px;
		outline: 0;
		box-sizing: border-box;
		-webkit-tap-highlight-color: transparent;
		cursor: text;
		transition: all ease .15s;
		&:hover, &:active, &:focus {
			border: 1px solid $primary;
		}
	}
	&__error {
		position: absolute;
		bottom: -34px;
		left: 0;
		color: #DB0202;
		font-size: 10px;
		font-weight: 600;
	}
}
