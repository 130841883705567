.dialog {
	//display: grid;
	//grid-template-columns: 3fr 1fr;
	//grid-gap: 30px;
	//height: inherit;
	display: flex;
	flex: 1;
	&__toggle{
		&-info{
			display: none;
		}
	}
	&__header {
		padding-bottom: 14.38px;
		border-bottom: 1px solid #E0EDF5;
		display: flex;
		display: -webkit-box;
		box-pack: justify;
		justify-content: space-between;
		align-items: center;
		& > * {
			margin: 0;
			font-size: 18px;
			font-weight: 600;
		}
	}
	&__left {
		display: flex;
		flex-direction: column;
		position: relative;
		flex: 3;
		//height: inherit;
	}
	&__right {
		margin-left: 30px;
		flex: 1;
		background-color: #fff;
		width: 290px;
	}
	&__messages {
		display: flex;
		//height: inherit;
		flex-direction: column;
		//max-height: 52vh;
		padding: 33px 0;
		overflow-y: scroll;
		overflow: -moz-scrollbars-none;
		-ms-overflow-style: none;
		&::-webkit-scrollbar { width: 0 !important }
	}
	&__text-field {
		margin-top: auto;
	}
	&__event-info {
		padding: 0 17px;
		&__item {
			display: flex;
			padding: 15px 0;
			&:not(:last-child) {
				border-bottom: 1px solid #F1F1F1;
			}
			&__icon {
				margin-right: 11px;
			}
			&__text {
				font-weight: 600;
				font-size: 14px;
			}
		}
	}
}

@media screen and (max-width: 1440px){
	.dialog {
		$main: &;
		grid-template-columns: 1fr;
		overflow: hidden;
		position: relative;
		&__messages {
			padding-bottom: 0;
		}
		&__toggle{
			&-info {
				display: block;
				&.close {
					position: absolute;
					top: 10px;
					right: 10px;
					color: #009AF0;
					width: 20px;
					height: 20px;
				}
			}
		}
		&__event-info {
			padding-top: 30px;
		}
		&__right {
			position: absolute;
			width: 100%;
			right: -100%;
			transition: right 0.3s ease-out;
			height: 100%;
			box-shadow: 0px 4px 4px rgba(159, 159, 159, 0.11);
			&.is-active {
				right: 0;
			}
		}
	}

}
