.c-text-field {
	display: flex;
	background: #FFFFFF;
	box-shadow: 2px 2px 4px rgba(41, 171, 226, 0.05);
	padding: 5px 13.3px;
	&__input {
		flex: 1;
		border: none;
		outline: none;
		padding: 15px 0;
		font-size: 14px;
		&::placeholder {
			color: #7793A4;
		}
	}
	&__files {
		display: grid;
		position: relative;
		padding-bottom: 15px;
		flex-wrap: wrap;
		grid-template-columns: repeat(auto-fill, minmax(8rem, 1fr));
		grid-auto-rows: 1fr;
		grid-gap: 10px;
		padding-right: 10px;
		//grid-template-columns: 1fr 1fr 1fr 1fr;
		//grid-row: 1 / 1;
		//grid-column: 1 / 1;
		&-progress {
			position: absolute;
			bottom: 0;
			left: 0;
		}
		//& > * {
		//	margin: 5px 10px;
		//}
	}
	&__file {
		width: 100%;
		height: 90px;
		display: flex;
		justify-content: center;
		align-items: center;
		background-position: center;
		background-size: cover;
		position: relative;
		&-remove {
			width: 20px;
			height: 20px;
			background-color: rgba(0,0,0,0.6);
			position: absolute;
			top: -8px;
			right: -8px;
			color: #FFFFFF;
			cursor: pointer;
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;
			& > svg {
				color: #FFFFFF;
			}
		}
		& > img {
			max-width: 100%;
		}
	}
	&__send-button {
		display: flex;
		align-items: center;
	}
	&__attach-button {
		margin-right: 8px;
		display: flex;
		align-items: center;
		& i {
			transform: rotate(225deg);
		}
	}
}
