.drawer {
    position: absolute;
    top: 0;
    right: 0;
    &__burger {
        display: none !important;
        & .hamburger-inner,
        & .hamburger-inner::before,
        & .hamburger-inner::after,
        &.hamburger.is-active .hamburger-inner,
        &.hamburger.is-active .hamburger-inner::before,
        &.hamburger.is-active .hamburger-inner::after{
            background-color: #FFFFFF;
        }
    }
    &__menu {
        display: flex;
        flex-direction: column;
        position: relative;
        padding: 30px 10px;

        &-close {
            position: absolute;
            left: 10px;
            top: 10px;
            color: #FFFFFF;
            margin-left: auto;
        }
        &-logo {
            text-align: center;
            padding: 15px 0;
            & > img {
                max-width: 200px;
            }
        }
        background-color: #009AF0;
        &-list {
            list-style-type: none;
            padding: 0;
        }
        &-item {
            color: #FFFFFF;
            font-size: 18px;
            padding: 10px 20px;
            font-weight: 600;
            border-bottom: 2px solid #FFFFFF;
            margin-bottom: 10px;
            & > a,& > a:visited, & > a:focus, & > a:active {
                text-decoration: none;
                color: #FFFFFF;
            }
        }

        &-user {
            display: flex;
            justify-content: center;
            align-items: center;
            & > span {
                margin-left: 10px;
                color: #FFFFFF;
                font-weight: 600;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .drawer {
        &__burger {
            display: block !important;
        }
    }
}

