// Text
.text {

	// Align
	&-center {
		text-align: center;
	}
	&-left {
		text-align: left;
	}
	&-right {
		text-align: right;
	}

	// Color
	&-white {
		color: #FFFFFF;
	}
	&-danger {
		color: red;
	}
}

// Opacity
.opacity {
	&-0 {
		opacity: 0;
	}
	&-1 {
		opacity: 0.1;
	}
	&-2 {
		opacity: 0.2;
	}
	&-3 {
		opacity: 0.3;
	}
	&-4 {
		opacity: 0.4;
	}
	&-5 {
		opacity: 0.5;
	}
	&-6 {
		opacity: 0.6;
	}
	&-7 {
		opacity: 0.7;
	}
	&-8 {
		opacity: 0.8;
	}
	&-9 {
		opacity: 0.9;
	}
}