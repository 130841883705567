.chat {
	display: flex;
	width: 1440px;
	height: 100%;
}

@media screen and (max-width: 1440px) {
	.chat {
		width: 100%;
	}
}
