@import '../../styles/variables.scss';

$spinkit-size: 44px !default;
$spinkit-spinner-color: $blue !default;

@keyframes sk-three-bounce {
    0%, 80%, 100% {
        transform: scale(0);
    }
    40% {
        transform: scale(1.0);
    }
}

.preloader{
    text-align: center;
    font-size: 18px;
    color: $blue;
    font-weight: bold;
    height: 100%;
    width: 100%;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex: 1;
}

.sk-three-bounce {
    $animationDuration: 1.4s;
    $delayRange: 0.32s;
    width: ($spinkit-size * 3);
    text-align: center;
    margin-bottom: 8px;
    .sk-child {
        width: $spinkit-size;
        height: $spinkit-size;
        background-color: $spinkit-spinner-color;

        border-radius: 100%;
        display: inline-block;
        animation: sk-three-bounce $animationDuration ease-in-out 0s infinite both;
    }

    .sk-bounce-1 {
        animation-delay: -$delayRange;
    }
    .sk-bounce-2 {
        animation-delay: (-$delayRange / 2);
    }
}
