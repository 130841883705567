.app-layout {
	display: flex;
	flex-direction: column;
	min-height: 100%;
	&.fullpage {
		height: 100%;
	}
	&__content {
		min-height: calc(100% - 272px);
	}
	> .app {
		height: 89.92vh;
		display: flex;
	}
}

@media screen and (max-width: 768px){
	.app-layout {
		> .app {
			height: auto;
			flex-direction: column-reverse;
		}
	}
}
