.share-modal {
    width: 440px;
    padding: 20px;
    &__title {
        color: #142837;
        text-align: center;
    }

    &__desc {
        text-align: center;
        font-weight: 500;
    }

    &__options-list{
        list-style-type: none;
        padding: 0;
    }

    &__options-item {
        padding: 30px 0;
        border-top: 1px solid #EEEEEE;
    }
}

@media screen and (max-width: 768px){
    .share-modal {
        min-width: initial;
        max-width: 500px;
        width: 100%;
    }
}
