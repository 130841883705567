@import '../../styles/variables.scss';

.map{
    position: absolute;
    height: 43px;
    left: 10px;
    top: 8px;
    &__move-controls{
        height: 100%;
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
    }
    label{
        background: #ffffff;
        padding: 0 9px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.11);
        height: 100%;
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #353535;
        cursor: pointer;
        margin-right: 8px;
        &:before{
            content: '';
            width: 27px;
            height: 27px;
            display: block;
            margin-right: 10px;
            border-radius: 4px;
            border: 1px solid rgba(131, 165, 217, 0.05);
            background: #dee6ec;
            background-position: center;
            background-repeat: no-repeat;
        }
    }
    #move-checkbox:checked + label:before{
        background-image: url('../../images/check.svg');
    }
    &__refresh{
        width: 43px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.11);
        background-image: url('../../images/refresh.svg');
        background-position: center;
        background-repeat: no-repeat;
        cursor: pointer;
        background-color: #fff;
        &:active{
            transform: scale(.95);
        }
    }
}

.cluster{
    img{
        display: none;
    }
    background: url('../../images/black_pin.svg');
    background-size: contain!important;
    background-position: center!important;
    transform: translate(0, -50%);
    & > div{
        width: 42px!important;
        height: 42px;
        border-radius: 50%;
        background: #fff;
        left: 10px!important;
        top: 9px!important;
        line-height: 42px!important;
        font-size: 22px!important;
        color: #2D2930!important;
        font-family: $rat!important;
    }
    &.blue{
        background: url('../../images/blue_pin.svg');
    }
    &.pink{
        background: url('../../images/pink_pin.svg');
    }
    &.green{
        background: url('../../images/green_pin.svg');
    }
}
